import PropTypes from 'prop-types';
import React from 'react';

import { cls } from '@mssgme/helpers';
import styles from './Cover.module.scss';

export default function Cover({ blockStyles, hasPadding, coverSrc, style: baseStyle, isMobileMode, ...rest }) {
    return (
        <div
            style={{
                borderTopLeftRadius: hasPadding ? blockStyles.borderRadius : 0,
                borderTopRightRadius: hasPadding ? blockStyles.borderRadius : 0,
                borderBottomLeftRadius: hasPadding ? blockStyles.borderRadius : 0,
                borderBottomRightRadius: hasPadding ? blockStyles.borderRadius : 0,
                backgroundColor: coverSrc ? 'transparent' : '#ccc',
                ...baseStyle,
            }}
            {...cls([styles.root, isMobileMode && styles.mobileMode], rest)}
        >
            <div className={styles.rootInner}>
                {coverSrc && <img src={coverSrc} alt="Cover" />}
            </div>
        </div>
    );
}

Cover.propTypes = {
    blockStyles: PropTypes.object.isRequired,
    style: PropTypes.object,
    hasPadding: PropTypes.bool,
    isMobileMode: PropTypes.bool,
    coverSrc: PropTypes.string,
};

Cover.defaultProps = {
    hasPadding: true,
    style: null,
    coverSrc: '',
};
