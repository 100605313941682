import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import TinyColor2 from 'tinycolor2';

import { GenericIcon } from '@mssgme/ui';
import { cls, ua, urlSafeComponent } from '@mssgme/helpers';
import { __t, prettifyUrl } from '@mssgme/shared';
import { useEventCallback } from '@mssgme/hooks';

import { useAction, usePageData } from '../../hooks';

import styles from './SmartButton.module.scss';

export function SmartButton({
    id,
    link,
    interactive,
    active,
    isViewMode,
    // todo: handle drafts
    // eslint-disable-next-line no-unused-vars
    isWebsitePublished,
    width,
    icon,
    urlSubtitle,
    onClick,
    style,
    ...rest
}) {
    const { _id: pageId } = usePageData();
    const {
        pageMeta: { title, name },
        isInternal,
        currentUrl,
        Tag,
        tagAttributes,
        scrollToHash,
    } = useAction({ action: link.action, isViewMode, interactive });
    const { iconStyle, buttonStyle } = useMemo(() => {
        if (!style) {
            return {};
        }

        const color = TinyColor2(style.backgroundColor)
            .darken(4)
            .toString();
        const backgroundColor = TinyColor2(style.backgroundColor)
            .setAlpha(0.5)
            .toString();

        return {
            iconStyle: { borderRight: `1px solid ${color}` },
            buttonStyle: active ? { ...style, backgroundColor } : style,
        };
    }, [style, active]);
    const prettyCurrentUrl = prettifyUrl(currentUrl);
    const displayTitle = link.title || title || name || prettyCurrentUrl;

    const handleClick = useEventCallback((e) => {
        if (scrollToHash) {
            scrollToHash();
        }

        if (!interactive) {
            return e.preventDefault();
        }

        if (onClick) {
            e.preventDefault();

            return onClick({ link });
        }

        if (ua.Android && !isInternal) {
            e.preventDefault();

            const { currentTarget } = e;
            const href = currentTarget.href;

            window.open(href);
        }
    });

    return (
        <div className={cn(styles.wrapper, styles[width], active && styles.active)}>
            <Tag
                data-element="custom-link"
                data-event-name={urlSafeComponent(currentUrl)}
                data-title={title || name || prettyCurrentUrl}
                data-page-id={pageId}
                data-id={id}
                id={id}
                style={buttonStyle}
                onClick={handleClick}
                {...tagAttributes}
                {...cls([styles.root, icon && styles.hasIcon, !interactive && styles.nonInteractive, 'link-button'], rest)}
            >
                {icon && (
                    <div className={styles.icon} style={iconStyle}>
                        <GenericIcon name={icon} size={20} />
                    </div>
                )}

                <div className={styles.content}>
                    <div>{displayTitle || __t('link_placeholder')}</div>
                    {urlSubtitle && <div className={styles.subTitle}>{prettyCurrentUrl}</div>}
                </div>
            </Tag>
        </div>
    );
}

SmartButton.propTypes = {
    id: PropTypes.string,
    width: PropTypes.oneOf(['adaptive', 'full']),
    icon: PropTypes.string,
    urlSubtitle: PropTypes.bool,
    link: PropTypes.object.isRequired,
    style: PropTypes.object.isRequired,
    active: PropTypes.bool,
    interactive: PropTypes.bool,
    isViewMode: PropTypes.bool,
    isWebsitePublished: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
};
