import config from 'config';

const HOMEPAGE_DEFAULT_NAME = {
    uk: 'Головна',
    ru: 'Главная',
    en: 'Homepage',
};

const WEBSITE_DEFAULT_NAME = {
    'Мій сайт': 'uk',
    'Мой сайт': 'ru',
    'My Website': 'en',
};

export const getPageName = (page, isHomePage) => {
    const name = page.name;

    if (isHomePage) {
        const language = (
            // pre v5.17, home page name is hardcoded to equal website name, use it to detect language
            (// else use fallback
                (WEBSITE_DEFAULT_NAME[name] || (typeof navigator !== 'undefined' && navigator.language)))
        );
        const resolved = HOMEPAGE_DEFAULT_NAME[language];

        if (resolved) {
            return resolved;
        }

        if (!name) {
            return HOMEPAGE_DEFAULT_NAME[config.fallback_language];
        }
    }

    return name;
};
