import cn from 'classnames';
import React from 'react';

import { cls } from '@mssgme/helpers';
import { ImageBlockKind } from '@mssgme/shared';
import { useBooleanHandler } from '@mssgme/hooks';
import { LandingsPropTypes } from '@mssgme/ui';
import { MediaSizes, useAction, useBlockTheme, useUploads } from '../../../hooks';
import { LightBox } from '../../../UI';
import { BlockBase, useBlockHorizontalInsetsPadding } from '../BlockBase';

import styles from './Image.module.scss';

export default function Image({ block, insets, interactive, isViewMode, ...rest }) {
    const { Tag, tagAttributes, scrollToHash } = useAction({ action: block.action, isViewMode, interactive });
    const { theme, style } = useBlockTheme(block);
    const { getMediaSizeUrl } = useUploads();
    const [isLightbox, openLightBox, closeLightBox] = useBooleanHandler();
    const absoluteUrl = getMediaSizeUrl(block.url, MediaSizes.Large);
    const shouldFit = theme.ratio !== 'original';
    const blockInsets = useBlockHorizontalInsetsPadding(insets, theme.padding);

    const handleClick = interactive && block.action.type === 'fullImage' ? openLightBox : null;

    return (
        <BlockBase lazy insets={blockInsets} {...cls([styles.root, styles[theme.alignment]], rest)}>
            <div
                className={cn(styles.imageBlock, {
                    [styles.placeholder]: !absoluteUrl,
                    [styles.clickable]: !!handleClick,
                    [styles[theme.ratio]]: styles[theme.ratio],
                    [styles[theme.fit]]: shouldFit && styles[theme.fit],
                })}
                style={style}
            >
                <Tag className={styles.imageContainer} { ...tagAttributes } onClick={scrollToHash}>
                    {shouldFit ? (
                        <div
                            className={styles.image}
                            style={absoluteUrl ? { backgroundImage: `url("${absoluteUrl}")` } : undefined}
                            title="Image"
                            onClick={handleClick}
                        />
                    ) : (
                        absoluteUrl && <img onClick={handleClick} src={absoluteUrl} alt="Image" />
                    )}
                </Tag>
            </div>

            {isLightbox && <LightBox activeIndex={0} images={[block]} onClose={closeLightBox} />}
        </BlockBase>
    );
}

Image.propTypes = {
    ...BlockBase.propTypes,
    block: LandingsPropTypes.block.isRequired,
};

Image.defaultProps = BlockBase.defaultProps;

Image.kind = ImageBlockKind;
