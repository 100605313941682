import React, { useState, useCallback, useEffect } from 'react';

import { cls } from '@mssgme/helpers';
import { __t, AccordionBlockKind } from '@mssgme/shared';
import { LandingsPropTypes } from '@mssgme/ui';
import { useBlockTheme } from '../../../hooks';
import { BlockBase } from '../BlockBase';

import styles from './Accordion.module.scss';

const AccordionItem = React.lazy(() => import('./AccordionItem'));

export default function Accordion({ block, ...rest }) {
    const {
        theme: { exclusive },
        theme,
    } = useBlockTheme(block, false);
    const [opened, setOpened] = useState([]);

    const isOpen = (item) => opened.includes(item);
    const handleToggle = useCallback(
        (item) => {
            const remove = opened.includes(item);

            if (exclusive) {
                setOpened(remove ? [] : [item]);
            } else {
                setOpened(remove ? opened.filter((i) => i !== item) : [...new Set([...opened, item])]);
            }
        },
        [opened, exclusive]
    );

    useEffect(() => {
        if (exclusive && opened.length > 1) {
            setOpened([opened[0]]);
        }
    }, [exclusive, opened]);

    return (
        <BlockBase lazy {...cls(styles.root, rest)}>
            {block.items.length ? (
                block.items.map((item, index) => (
                    <AccordionItem
                        key={item._id || index}
                        item={item}
                        isOpen={isOpen(item)}
                        theme={theme}
                        onToggle={handleToggle}
                    />
                ))
            ) : (
                <div style={{ opacity: 0.3 }}>
                    <AccordionItem item={{ title: __t('question') }} theme={theme} />
                    <AccordionItem item={{ title: __t('question') }} theme={theme} />
                    <AccordionItem item={{ title: __t('question') }} theme={theme} />
                </div>
            )}
        </BlockBase>
    );
}

Accordion.propTypes = {
    ...BlockBase.propTypes,
    block: LandingsPropTypes.block.isRequired,
};

Accordion.defaultProps = BlockBase.defaultProps;

Accordion.kind = AccordionBlockKind;
