import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { cls } from '@mssgme/helpers';
import { ShoppingCartIcon } from '@mssgme/icons';
import { useCoreContext, useSavedOrders, useWebsiteData } from '../../hooks';

import styles from './ShoppingCartButton.module.scss';

export const ShoppingCartButton = ({ steady, absolute, ...rest }) => {
    const { enabledPayments } = useWebsiteData();
    const { currentOrder } = useSavedOrders();
    const { router } = useCoreContext();
    const orderItemsLength = currentOrder?.items.length;
    const shouldLoad = enabledPayments && orderItemsLength;
    const orderId = currentOrder?._id;
    const orderUrl = orderId && router.orders.toView(orderId);

    if (!shouldLoad) {
        return null;
    }

    return (
        <Link to={orderUrl} {...cls(cn([styles.root, steady && styles.steady, absolute && styles.absolute]), rest)}>
            <ShoppingCartIcon className={styles.icon} size={20} />
            <div className={styles.badge}>{orderItemsLength}</div>
        </Link>
    );
};

ShoppingCartButton.propTypes = {
    steady: PropTypes.bool,
    absolute: PropTypes.bool,
};
