import React from 'react';
import { FiCode } from 'react-icons/fi';

import { __t, CustomCodeBlockKind } from '@mssgme/shared';
import { cls } from '@mssgme/helpers';
import { LandingsPropTypes, InnerHTMLWithScripts } from '@mssgme/ui';

import { useBlockTheme } from '../../../hooks';
import { BlockBase } from '../BlockBase';

import styles from './CustomCode.module.scss';

export default function CustomCode({ block, interactive, isViewMode, ...rest }) {
    const { theme } = useBlockTheme(block);

    return (
        <BlockBase {...cls(styles.root, rest)}>
            {interactive && !isViewMode ? (
                <InnerHTMLWithScripts html={block.content} />
            ) : (
                <div className={styles.preview} style={{ ...theme }}>
                    <div className={styles.header}>
                        <FiCode size={20} />
                        {__t('html_code')}
                    </div>
                    {block.content.slice(0, 200) + (block.content.length > 240 ? '...' : '')}
                </div>
            )}
        </BlockBase>
    );
}

CustomCode.propTypes = {
    ...BlockBase.propTypes,
    block: LandingsPropTypes.block.isRequired,
};

CustomCode.defaultProps = BlockBase.defaultProps;

CustomCode.kind = CustomCodeBlockKind;
