import cn from 'classnames';
import React, { useMemo } from 'react';

import { __t, ListBlockKind } from '@mssgme/shared';
import { cls } from '@mssgme/helpers';
import { LandingsPropTypes } from '@mssgme/ui';
import { useBlockTheme } from '../../../hooks';
import { BlockBase } from '../BlockBase';

import styles from './List.module.scss';

export default function List({ block, style: baseStyle, ...rest }) {
    const { theme, style } = useBlockTheme(block);
    const combined = useMemo(() => (baseStyle ? { ...style, ...baseStyle } : style), [style, baseStyle]);
    const Tag = theme.listLabel === 'number' ? 'ol' : 'ul';

    return (
        <BlockBase style={combined} {...cls(styles.root, rest)}>
            {block.items.length > 0 && (
                <Tag className={cn(styles.list, styles[theme.listLabel])}>
                    {block.items.map(({ content }, index) => (
                        <li key={index}>{content}</li>
                    ))}
                </Tag>
            )}
            {block.items.length === 0 && (
                <ul className={cn(styles.list, styles[theme.listLabel])}>
                    <li>{__t('loremListItemFirst')}</li>
                    <li>{__t('loremListItemSecond')}</li>
                </ul>
            )}
        </BlockBase>
    );
}

List.propTypes = {
    ...BlockBase.propTypes,
    block: LandingsPropTypes.block.isRequired,
};

List.defaultProps = BlockBase.defaultProps;

List.kind = ListBlockKind;
