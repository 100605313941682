import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { FiX } from 'react-icons/fi';

import NotificationCenter, { NOTIFY_STYLES } from './notification-center';
import styles from './Notification.module.scss';

const propTypes = {
    className: PropTypes.string,
    onClose: PropTypes.func,
    children: PropTypes.node,
    notifyStyle: PropTypes.oneOf(Object.values(NOTIFY_STYLES)),
    isRaw: PropTypes.bool,
    showClose: PropTypes.bool,
};

const defaultProps = {
    closeDelay: 0,
    isRaw: false,
    notifyStyle: NOTIFY_STYLES.success,
    showClose: false,
};

function Notification({ className, notifyStyle, showClose, isRaw, onClose, children }) {
    const handleCloseClick = useCallback(
        (e) => {
            e.preventDefault();
            onClose && onClose();
        },
        [onClose]
    );

    return (
        <div className={classNames(styles.root, className, styles[notifyStyle])}>
            {isRaw ? (
                <div className={styles.content} dangerouslySetInnerHTML={{ __html: children }} />
            ) : (
                <div className={styles.content}>{children}</div>
            )}
            {showClose && (
                <a className={styles.close} href="#" onClick={handleCloseClick}>
                    <FiX size={20} color="#fff" />
                </a>
            )}
        </div>
    );
}

/**
 * @param {'notify'|'success'|'danger'} type Notification type
 * @returns {function(message: string, options: { showClose: boolean; closeDelay?: number }): number} Show notification
 */
function notify(type) {
    return (...args) => {
        const center = NotificationCenter.getInstance();

        return center[type].apply(center, args);
    };
}

Notification.propTypes = propTypes;
Notification.defaultProps = defaultProps;

/** @deprecated Use NotificationCenter.getInstance().notify() instead. */
Notification.notify = notify('notify');
/** @deprecated Use NotificationCenter.getInstance().success() instead. */
Notification.success = notify('success');
/** @deprecated Use NotificationCenter.getInstance().danger() instead. */
Notification.danger = notify('danger');

export default Notification;
