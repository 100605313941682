import React, { useMemo } from 'react';

import { __t, HeadingBlockKind } from '@mssgme/shared';
import { cls } from '@mssgme/helpers';
import { LandingsPropTypes } from '@mssgme/ui';
import { useBlockTheme } from '../../../hooks';
import { BlockBase } from '../BlockBase';

import styles from './Heading.module.scss';

const headingTagMap = {
    huge: 'h1',
    large: 'h2',
    medium: 'h3',
    small: 'h4',
};

export default function Heading({ block, block: { _id, content }, style: baseStyle, ...rest }) {
    const { theme, style } = useBlockTheme(block);
    const combined = useMemo(() => (baseStyle ? { ...style, ...baseStyle } : style), [style, baseStyle]);
    const HeadingTag = headingTagMap[theme.size || 'h1'];

    return (
        <BlockBase style={combined} {...cls(styles.root, rest)}>
            {content && <HeadingTag>{content}</HeadingTag>}
            {!_id && !content && <HeadingTag style={{ color: '#e0e0e0' }}>{__t('header')}</HeadingTag>}
        </BlockBase>
    );
}

Heading.propTypes = {
    ...BlockBase.propTypes,
    block: LandingsPropTypes.block.isRequired,
};

Heading.defaultProps = BlockBase.defaultProps;

Heading.kind = HeadingBlockKind;
