import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { TimerBlockKind, __t } from '@mssgme/shared';
import { cls, keyHash } from '@mssgme/helpers';
import { LandingsPropTypes } from '@mssgme/ui';
import { useBlockTheme } from '../../../hooks';
import { BlockBase } from '../BlockBase';

import styles from './Timer.module.scss';

const zeroPad = (num) => String(num).padStart(2, '0');
const identity = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
};
const calculateTimeLeft = (expireDate) => {
    const difference = expireDate - Date.now();

    if (difference > 0) {
        return {
            days: ~~(difference / (1000 * 60 * 60 * 24)),
            hours: ~~((difference / (1000 * 60 * 60)) % 24),
            minutes: ~~((difference / (1000 * 60)) % 60),
            seconds: ~~((difference / 1000) % 60),
        };
    }

    return identity;
};

export default function Timer({ block, style: baseStyle, ...rest }) {
    const { style } = useBlockTheme(block);
    const expireDate = useMemo(() => new Date(block.expireDate).getTime(), [block.expireDate]);
    const [timeLeft, setTimeLeft] = useState(() => calculateTimeLeft(expireDate));
    const combined = useMemo(() => (baseStyle ? { ...style, ...baseStyle } : style), [style, keyHash(baseStyle)]);

    useEffect(() => {
        const timer = setInterval(() => setTimeLeft(calculateTimeLeft(expireDate)), 1000);

        return () => {
            clearInterval(timer);
        };
    }, [expireDate]);

    return (
        <BlockBase style={combined} {...cls([styles.root], rest)}>
            {Object.entries(timeLeft).map(([interval, value]) => (
                <TimerDigits key={interval} interval={interval} value={value} />
            ))}
        </BlockBase>
    );
}

Timer.propTypes = {
    ...BlockBase.propTypes,
    block: LandingsPropTypes.block.isRequired,
};

Timer.defaultProps = BlockBase.defaultProps;

Timer.kind = TimerBlockKind;

export const TimerDigits = ({ interval, value }) => {
    return (
        <div className={styles.timerPeriod}>
            <div className={styles.counter}>{zeroPad(value)}</div>
            <div className={styles.label}>{__t(interval)}</div>
        </div>
    );
};

TimerDigits.propTypes = {
    interval: PropTypes.string.isRequired,
    value: PropTypes.number,
};
