import React, { useMemo } from 'react';

import { __t, TextBlockKind } from '@mssgme/shared';
import { cls } from '@mssgme/helpers';
import { LandingsPropTypes } from '@mssgme/ui';
import { useBlockTheme } from '../../../hooks';
import { BlockBase } from '../BlockBase';

import styles from './Text.module.scss';

export default function Text({ block, block: { content }, style: baseStyle, ...rest }) {
    const { style } = useBlockTheme(block);
    const combined = useMemo(() => (baseStyle ? { ...style, ...baseStyle } : style), [style, baseStyle]);

    return (
        <BlockBase style={combined} {...cls(styles.root, rest)}>
            {content && <p>{content}</p>}
            {!block._id && !content && <p style={{ color: '#e0e0e0' }}>{__t('lorem')}</p>}
        </BlockBase>
    );
}

Text.propTypes = {
    ...BlockBase.propTypes,
    block: LandingsPropTypes.block.isRequired,
};

Text.defaultProps = BlockBase.defaultProps;

Text.kind = TextBlockKind;
