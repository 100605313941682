import React from 'react';

import { __t, FeaturesBlockKind } from '@mssgme/shared';
import { cls } from '@mssgme/helpers';
import { LandingsPropTypes } from '@mssgme/ui';
import { useBlockTheme } from '../../../hooks';
import { BlockBase } from '../BlockBase';
import { FeaturesItem } from './FeaturesItem';

import styles from './Features.module.scss';

export default function Features({ block, ...rest }) {
    const { style } = useBlockTheme(block);

    return (
        <BlockBase {...cls(styles.root, rest)}>
            {block.items.length ? (
                block.items.map((item, index) => <FeaturesItem style={style} key={item._id || index} item={item} />)
            ) : (
                <div style={{ opacity: 0.3 }}>
                    <FeaturesItem style={style} item={{ title: __t('header'), content: __t('lorem') }} />
                    <FeaturesItem style={style} item={{ title: __t('header'), content: __t('lorem') }} />
                </div>
            )}
        </BlockBase>
    );
}

Features.propTypes = {
    ...BlockBase.propTypes,
    block: LandingsPropTypes.block.isRequired,
};

Features.defaultProps = BlockBase.defaultProps;

Features.kind = FeaturesBlockKind;
