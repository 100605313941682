import React from 'react';
import { Button, Logo } from '@mssgme/ui';
import { __t } from '@mssgme/shared';
import config from 'config';

import { ThemedModal } from '../../UI';

import styles from './PastDueModal.module.scss';

const BUTTON_URL = `${config.admin_url}/auth/login?utm_source=userpage&utm_medium=unpublished&utm_campaign=unpublished`;

export default function PastDueModal() {
    return (
        <ThemedModal compact overlay showHeader={false} onClose={false} noEscape>
            <div className={styles.content}>
                <Logo className={styles.logo} />
                <h4 className={styles.heading}>{__t('unpublished_website')}</h4>
                <p className={styles.text}>
                    {__t('unpublish_reason')}
                    <br />
                    {__t('owner_goto_account')}
                </p>
                <Button tag="a" href={BUTTON_URL} size="large" fullWidth>
                    {__t('login_account')}
                </Button>
            </div>
        </ThemedModal>
    );
}
