import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { generateDeepLink, ua } from '@mssgme/helpers';
import { messengerIcons } from '@mssgme/ui';

import styles from './MessengerButton.module.scss';

const generateHref = (messenger) => {
    return !ua.isMobile && messenger.platform === 'viber'
        ? messenger.redirectUrl
        : generateDeepLink(messenger, messenger.params).getUrl();
};
const showLabel = (theme) => {
    return theme.messengersMode !== 'compact';
};

export default function MessengerButton({ index, count, messenger, interactive, className, theme, style, ...rest }) {
    const modifiedMessenger = {
        ...messenger,
        messenger: messenger.platform,
        name: messenger.value,
    };
    const platform = modifiedMessenger.platform;
    const isMultiple = count > 1;
    const notFirst = isMultiple && !!index;
    const notLast = isMultiple && !(index >= count - 1);
    const Tag = interactive ? 'a' : 'div';
    const Icon = messengerIcons[platform];
    const shouldSqueeze = count > 4 && theme.messengersMode === 'beefy';

    const handleClick = (e) => {
        if (!interactive) {
            return e.preventDefault();
        }

        if (ua.Android) {
            e.preventDefault();

            const { currentTarget } = e;
            const href = currentTarget.href;

            window.open(href);
        }
    };

    return (
        <Tag
            className={cn(
                styles.root,
                styles[platform],
                styles[theme.messengersMode],
                {
                    [styles.notFirst]: notFirst,
                    [styles.notLast]: notLast,
                    [styles.themeColors]: theme.themeColors,
                    [styles.squeeze]: shouldSqueeze,
                },
                className
            )}
            target="_blank"
            rel="noopener noreferrer"
            href={generateHref(modifiedMessenger)}
            data-messenger={platform}
            style={{ ...style, color: theme.themeColors ? style.color : '#fff' }}
            {...rest}
            onClick={handleClick}
        >
            <Icon className="logo" />
            <div className={styles.splitter} />
            {(!isMultiple || showLabel(theme)) && <span />}
        </Tag>
    );
}

MessengerButton.propTypes = {
    messenger: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    style: PropTypes.object.isRequired,
    count: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    interactive: PropTypes.bool.isRequired,
    className: PropTypes.string,
};

MessengerButton.defaultProps = {
    className: '',
};
