import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { cls } from '@mssgme/helpers';
import { ClipboardIcon } from '@mssgme/icons';
import { ShoppingCartButton } from '../ShoppingCartButton';
import { useWebsiteData, useSavedOrders, useCoreContext } from '../../hooks';

import styles from './BuyerActions.module.scss';

export const BuyerActions = (props) => {
    const { enabledPayments } = useWebsiteData();
    const { router } = useCoreContext();
    const { currentOrder, userOrders } = useSavedOrders();
    const hasFinalizedOrders = userOrders.filter((order) => order.finalized).length > 0;
    const isSingleOrdersButton = !(enabledPayments && currentOrder?.items.length);

    return (
        <div {...cls(styles.root, props)}>
            {hasFinalizedOrders && (
                <Link
                    to={router.orders.toList()}
                    className={cn([styles.ordersButton, isSingleOrdersButton && styles.single])}
                >
                    <ClipboardIcon size={isSingleOrdersButton ? 20 : 16} />
                </Link>
            )}

            <ShoppingCartButton steady />
        </div>
    );
};
